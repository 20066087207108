import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const sectionCategoriesApi = createApi({
  reducerPath: "sectionCategoriesApi", 
  baseQuery,
  tagTypes: ["sectionCategories"],
  endpoints: (builder) => ({
    getSectionCategories: builder.query({
      query: () => `/global/section-categories`,
      providesTags: ["sectionCategories"],
    }),
    getSection: builder.query({
      query:(arg) => `/global/sections?section_category=${arg}`,
      providesTags:["section"],
    })
  }),
  
});


export const { useGetSectionCategoriesQuery, useGetSectionQuery } = sectionCategoriesApi; 
